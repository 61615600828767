$scale: 14px !default;
$breakpoint:992px !default;

@import 'src/assets/layout/styles/layout/fonts';
@import 'src/assets/layout/styles/layout/utils';
@import 'src/assets/layout/styles/layout/typography';
@import 'src/assets/layout/styles/layout/animation';
@import 'src/assets/layout/styles/layout/topbar/topbar';
@import 'src/assets/layout/styles/layout/sidebar/sidebar';
@import 'src/assets/layout/styles/layout/menuprofile';
@import 'src/assets/layout/styles/layout/megamenu';
@import 'src/assets/layout/styles/layout/landing';
@import 'src/assets/layout/styles/layout/main';
@import 'src/assets/layout/styles/layout/content';
@import 'src/assets/layout/styles/layout/footer';
@import 'src/assets/layout/styles/layout/config';
@import 'src/assets/layout/styles/layout/breadcrumb';
@import 'src/assets/layout/styles/layout/responsive';
