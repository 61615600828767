/* Layout */
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

/* Quill Text Editor for p-editor */
@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

/* Demos */
@import "assets/demo/styles/flags/flags.css";
@import "assets/demo/styles/badges.scss";
@import "assets/demo/styles/code.scss";

:root {
  --red-50:#fdf5f6;
  --red-100:#f7cfd2;
  --red-200:#f0a8af;
  --red-300:#e9828c;
  --red-400:#e35b68;
  --red-500:#dc3545;
  --red-600:#bb2d3b;
  --red-700:#9a2530;
  --red-800:#791d26;
  --red-900:#58151c;

  // Harbour Blue ( Dusty Klaus )

  //--primary-color:#427191;

  //--primary-50:#dde8ef;
  //--primary-100:#bbd1e0;
  //--primary-200:#98bad0;
  //--primary-300:#76a2c1;
  //--primary-400:#548bb1;
  //--primary-500:#427191;
  //--primary-600:#39617c;
  //--primary-700:#2f5168;
  //--primary-800:#264153;
  //--primary-900:#1c303e;

  //Poppy Klaus

  --primary-color:#0073a5;

  --primary-50:#e6f1f6;
  --primary-100:#cce3ed;
  --primary-200:#b3d5e4;
  --primary-300:#99c7db;
  --primary-400:#80b9d2;
  --primary-500:#66abc9;
  --primary-600:#4d9dc0;
  --primary-700:#338fb7;
  --primary-800:#1a81ae;
  --primary-900:#0073a5;

  --yellow: #ffcc00;
  --crimson:#e84a5f;
  --green:#4caf50;
  --pink:#f06292;
  --brown:#795548;
  --purple:#9c27b0;
  --orange:#ff9800;
  --cyan:#00bcd4;
  --slate:#607d8b;
  --gold:#ffd700;


  --border-radius-lg: 8px;
  --box-shadow-light: 0px 2px 4px -1px rgba(10, 9, 11, 0.02), 0px 5px 13px -5px rgba(10, 9, 11, 0.05);

}

body{
  //background: #ebf0f4;
}

.p-button{
 background: var(--primary-color);
//  color: var(--primary-50);
}

.p-sidebar-content{
  height:100%;
}

.layout-topbar-item.incoming-call {
  .layout-topbar-action{
    i {
      color: var(--primary-500)
    }
  }
}
/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

/* custom utility classes */
.overflow-wrap-anywhere {
  overflow-wrap: anywhere;
}
.text-2xs {
  font-size: 0.625rem;
}
.max-h-page{
  max-height: calc(100vh - 225px);
}

.shadow-b-2 {
  box-shadow: rgba(0, 0, 0, 0.45) 0 15px 20px -20px;
}

.fa-icon > span.p-button-icon {
    font-size: x-large;
}

.textarea-blank, .textarea-blank:focus {
  border: none;
  background: transparent;
  -webkit-appearance: none;
  -moz-apperarance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  outline: none;
  padding: 0;
  resize: none;
  width: 100%;
  overflow: hidden;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  font-family: var(--font-family);
}

.textarea-blank.p-inputtext:enabled:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
}

.panel-blank > .p-panel{
  padding: 0;
  border: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0 none;
  border-radius: 0;
  box-shadow: none;
}

.panel-blank > .p-panel > .p-panel-content{
  padding: 0;
  border: 0;
  background: transparent;
  color: rgba(0, 0, 0, 0.87);
  border-top: 0 none;
  border-radius: 0;
  box-shadow: none;
}
.panel-blank > .p-panel > .p-panel-header{
  padding: 1rem;
  display: flex;
  justify-content: space-between;
}

.panel-blank > .p-panel > .p-panel-header >.p-panel-icons-end{
  display: flex;
  align-items: center;
}

.mvs-widget {

  background-color: var(--surface-card) !important;
  padding: 0.4rem 0.875rem 0.4rem 0.875rem;
  box-shadow: var(--box-shadow-light) !important;
  border-radius: var(--border-radius-lg) !important;

  .mvs-wigdet-menu-btn{
    margin-right: -.8rem
  }
  .p-panel{
    .p-panel-header{
      padding:0;
      //color: #111;
    }
    .p-panel-content{
      padding:0;
    }
  }
  .p-card{
    .p-card-content{
      padding: 0;
    }
  }

}

.p-chips-multiple-container{
  width:100%;
}

li:has(.li-hover):hover {
  background-color: var(--surface-50);
}
/*
.p-panel .p-panel-header{
  justify-content: space-between;
  flex-direction: row-reverse;
}
 */
/* components */

.quickview {
  background-color: var(--surface-card);
  padding: .5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12);
  border-radius: var(--border-radius);
  display:flex !important;
  flex-direction:column;
  justify-content:center;
  //height: 86px;

  &.--workflowA {
    border-top: solid 6px var(--primary-color);
    padding-top: calc(.5rem - 3px) !important;
    padding-bottom: calc(.5rem - 3px) !important;
    border-top-left-radius: 15px;
    height: auto;
    &:before {
      content: "";
      position:absolute;
      top: -12px;
      right: -6px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent var(--primary-color);
    }
  }
  &.--workflowS{
    border-right: solid 6px var(--primary-color);
    padding-left: 1rem;
    padding-right: 1rem;
    min-height: 32.5px;
    height: auto;
    &:before{
      content: '';
      position:absolute;
      top:0;
      left: 0;
      width: 0;
      height: 0;
      border-style:solid;
      border-width: 16px 0 16px 8px;
      border-color: transparent transparent transparent var(--primary-color);
    }
    &:after{
      content: '';
      position:absolute;
      top:0;
      right: 0;
      width: 0;
      height: 0;
      border-style:solid;
      border-width: 16px 8px 16px 0;
      border-color: transparent var(--primary-color) transparent transparent;
    }
  }
  &.--workflowL{
    padding-left: 1rem;
    height: 118px;
    &:before{
      content: '';
      position:absolute;
      top:0;
      left: 0;
      width: 0;
      height: 0;
      border-style:solid;
      border-width: 59px 0 59px 8px;
      border-color: transparent transparent transparent var(--primary-color);
    }
  }
  &.--workflow{
    padding-left: 1rem;
    &:before{
      content: '';
      position:absolute;
      top:0;
      left: 0;
      width: 0;
      height: 0;
      border-style:solid;
      border-width: 43px 0 43px 8px;
      border-color: transparent transparent transparent var(--primary-color);
    }
  }

  &.--ticket{
    border-right: solid 6px var(--primary-color);
    padding-left: 1rem;
    padding-right: 1rem;
    height: auto;
    min-height: 32.5px;
    &:before{
      content: '';
      position:absolute;
      top: calc(50% - 9.5px);
      left: -4px;
      display: block;
      width: 10px;
      height: 20px;
      border-radius: 0 100% 100% 0 / 0 50% 50% 0;
      background-color: var(--primary-color);
    }
    &:after{
      content: '';
      position:absolute;
      top: calc(50% - 9.5px);
      right: -4px;
      display: block;
      width: 10px;
      height: 20px;
      border-radius: 100% 0 0 100% / 50% 0 0 50%;
      background-color: var(--primary-color);
    }
  }
  position: relative;
  min-width: 150px;
  min-height: 75px;
  border-left: solid 6px var(--primary-color);

  .header{
    display:flex;
    justify-content:space-between;
    font-weight: 600;
    color: var(--surface-900);

    font-size: 1.125rem;
    span {
      font-size: 1rem;
      font-weight: 400;
      color: var(--surface-500);
    }
  }
  .content{
    display:flex;
    justify-content:space-between;
    font-weight: 500;
    color: var(--surface-900);
    margin:.25rem 0;
    font-size: 1rem;
    span{
      font-weight: 400;
    }
  }
  .footer {
    display:flex;
    justify-content:space-between;
    font-weight: 400;
    color: var(--surface-500);
    margin-top:.25rem;
    font-size: 1rem;
  }
}

.p-breadcrumb ul li:last-of-type{
    font-weight: 500;
}


.p-progressbar {
  height:1.6rem;
  border-radius: .32rem;
  //.p-progressbar-value {
  //  background: var(--primary-color);
  //}
}

/*.p-button:enabled:hover {
  background: rgba(96, 125, 139, 0.92) !important;
  color: #ffffff !important;
} */

.cursor {
  cursor: pointer;
}

*::-webkit-scrollbar-thumb {
  background: rgba(96, 125, 139, 0.92);
}

*::-webkit-scrollbar-track {
  border: 1px solid silver;
  background: #f1f1f1;
}
*::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}

.template-component-treenode .p-treenode {
    padding: 0 !important;
    margin: 0 !important;
}

.template-component-treenode .p-treenode-content {
    padding: 0 !important;
    margin: 0 !important;
    //height: 16px !important;
    overflow: hidden;
}
.template-component-treenode .p-treenode-content .p-treenode-label {
  width: 100%;
  padding-right: 10px;
}

.bold {
  font-weight: bold;
}

.pointer{
  cursor: pointer;
 }

//TODO: CODE REVIEW
// customer related avatars
//.cr-customer-group-avatar .cr-customer-custom-avatar {
//  margin-left: -0.4rem;
//}

//.cr-customer-custom-avatar {
//  .p-badge {
//    min-width: 1rem;
//    height: 1rem;
//    line-height: 1rem;
//    top: -4px !important;
//    right: 12px !important;
//  }

//  .p-avatar.p-avatar-circle{
//    overflow: unset;
//    margin:14px 0px;
//   box-shadow: 0px 1px 2px 1px #80808059;
//    font-size: 12px;
// }

//  .p-avatar .p-avatar-icon {
//    font-size: 9px;
//   font-weight: bold;
//}

//}


.wf-overview-plus-button {
  .p-button.p-button-icon-only {
    width: 1.75rem;
    padding: 0.243rem;
}
}

.config-background{
  background: radial-gradient(circle, var(--surface-300) 2px, var(--surface-ground) 2px);
  background-size: 20px 20px;
}

.configuration-fieldset .p-fieldset-legend {
  border: 1px solid var(--surface-300);
  margin-left: 10px
}


//p-select-button active state changed
.active-select-button.p-selectbutton .p-button.p-highlight {
  background: var(--primary-50);
  color: var(--blue-900);
  font-weight: 500;
}
.active-select-button.p-selectbutton .p-buttonset .p-button {
  min-width: 10rem;
}

.mvs-search-result-match {
  color: var(--primary-900);

}

.word-break {
  word-break: break-word;
}

.pac-item {
  padding: 10px 4px;
  cursor: pointer;
}

//  css for form fields disabled/readOnly
.custom-field-disabled:focus-visible,
.custom-field-disabled:focus {
  outline: unset;
  box-shadow: none;
}

.p-slider.p-disabled .p-slider-handle {
  background: var(--surface-400);
  border: 0;
}

.p-disabled, .p-component:disabled, .custom-field-disabled, .custom-checkbox-disabled .p-checkbox-box, .custom-select-button-disabled.p-selectbutton .p-button, .custom-editor-disabled .ql-disabled .ql-editor{
  background: var(--surface-200) !important;;
  cursor: not-allowed !important;;
  opacity: 0.8 !important;
}

.p-fileupload .p-disabled {
  display: inline-block;
}

p-inputnumber.ng-invalid > .p-inputnumber > .p-inputtext {
  border-color: #e24c4c;
}


p-calendar.ng-invalid > .p-calendar > .p-inputtext {
  border-color: #e24c4c;
}
p-calendar.ng-invalid .p-calendar-w-btn {
  border-color: #e24c4c;
}


p-dropdown.ng-invalid > .p-dropdown {
  border-color: #e24c4c;
}

p-multiselect.ng-invalid > .p-multiselect {
  border-color: #e24c4c;
}

p-treeselect.ng-invalid > .p-treeselect {
  border-color: #e24c4c;
}

p-autoComplete.ng-invalid > .p-autoComplete {
  border-color: #e24c4c;
}


p-tristatecheckbox.ng-invalid > .p-checkbox > .p-checkbox-box {
  border-color: #e24c4c;
}


p-slider.ng-invalid >  .p-slider {
  border-color: #e24c4c;
}

p-selectbutton.ng-invalid > .p-selectbutton > .p-button {
  border-color: #e24c4c;
}

.bg-transparent {
  background: transparent;
}
.bg-transparent-tabview.p-tabview .p-tabview-panels {
  background: transparent;
  padding: 0;
}

.no-header .p-panel-header {
  padding: 0 !important;
}

.ellipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}